<template>
  <div class="view-container">
    <div class="fp-container">
      <div class="fp-content banner-limit">
        <div class="form-content">
          <el-steps :active="active" finish-status="success" simple class="steps-container">
            <el-step title="账号信息" icon="none"></el-step>
            <el-step title="重置成功" icon="none"></el-step>
          </el-steps>

          <!--验证手机验证码-->
          <div class="info-form" v-show="active == 0">
            <el-form label-width="100px" :rules="rules" :model="infoForm" ref="infoForm" :inline="true">
              <el-form-item label="用户账号" prop="loginid">
                <el-input v-model="infoForm.loginid" name="loginid"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="phone">
                <el-input v-model="infoForm.phone" maxlength="11" name="phone" @input="infoForm.phone=infoForm.phone.replace(/^(0+)|[^\d]+/g, '')"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="code">
                <el-input v-model="infoForm.code" name="code"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button :disabled="btnAbled" type="primary" @click="sendCode">{{sendBtnMessage}}</el-button>
              </el-form-item>
              <el-form-item label="新密码" prop="password">
                <el-input v-model="infoForm.password" name="password" show-password></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="repassword">
                <el-input v-model="infoForm.repassword" name="repassword" show-password></el-input>
              </el-form-item>
              <el-button class="commitButton" type="primary" @click="modifyPwd">验证</el-button>
            </el-form>
          </div>

          <!--重置成功-->
          <div class="info-form view2" v-show="active == 1">
            密码修改成功，点击<a @click="gotoLogin">重新登录</a>
          </div>
        </div>
      </div>

    </div>
    <div class="footer-container">
      <fixed-footer></fixed-footer>
    </div>
  </div>
</template>

<script>
  import {Encrypt,Generatekey} from '@/utils/aes.js'
  import { FixedFooter } from "@/layout/components/index";
  import util from '@/utils/util.js'
  export default {
    name: "FindPwd",
    components: {
      FixedFooter,
    },
    data() {
      var validatePhone = (rule, value, callback) =>{
        setTimeout(() => {
          if(!value){
            callback(new Error('电话不能为空'));
          }
          else if (!Number.isInteger(parseInt(value))) {
            callback(new Error('请输入数字值'));
          } else {
            if (value.length != 11 || !util.isMobile(value)) {
              console.log('请输入正确的电话格式');
              callback(new Error('请输入正确的电话格式'));
            } else {
              callback();
            }
          }
        });
      }

      var validatePwd = (rule, value, callback) =>{
        setTimeout(() => {
          if(!value){
            callback(new Error('密码不能为空'));
          }
          else {
            const reg = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[~\\`!@{}|\[\];:'"<>,./?#$%\^&*?().+=\-_]).*$/;
            if (reg.test(value)) {
              callback();
            } else {
              return callback(
                new Error("最少6位，包含大写字母，小写字母，数字，特殊字符")
                // new Error("最少6位，包含小写字母，数字")
              );
            }
          }
        });
      }

      var validateCheckPwd = (rule, value, callback) =>{
        setTimeout(() => {
          if(!value || this.infoForm.password.indexOf(value) != 0){
            callback(new Error('两次密码不一致'));
          }else {
            callback();
          }
        });
      }

      var checkRepwd = (rule, value, callback) =>{
        setTimeout(() => {
          if(this.infoForm.password != value){
            callback(new Error('两次密码不一致'));
          }else {
            callback();
          }
        });
      }
      return {
        rules: {
          phone: [
            { validator: validatePhone, trigger: 'change' },
            { required: true, message: '请输入电话', trigger: 'blur' }
          ],
          code: [
            {required: true, trigger: 'change', message: '验证码不能为空'},
            {required: true, trigger: 'blur', message: '验证码不能为空'}
          ],
          password: [
            {validator: validatePwd, trigger: 'blur'},
            {required: true, message: '请输入新密码', trigger: 'blur' }
          ],
          repassword:[
            {validator: validateCheckPwd, trigger: 'change'},
            {validator: checkRepwd, trigger: 'blur'},
          ],
        },
        active: 0,
        status: this.$route.query.activeType,
        sendBtnMessage: '发送验证码',
        btnAbled: false,
        infoForm: {
          phone: '18587876597',
          loginid: '',
          code: '',
          password: '',
          repassword: '',
          uuid: '',
        },

      };
    },
    methods: {
      /**
       * 发送手机验证码
       */
      sendCode(formName) {
        let phone = this.infoForm.phone;
        console.log("手机号：",phone)
        this.$refs.infoForm.validateField('phone',(valid)=>{
          if(!valid){
            this.btnAbled = true;
            let i = 0;
            let myInt = setInterval(()=>{
              i++;
              if(i == 60){
                clearInterval(myInt);
                this.btnAbled = false;
                this.sendBtnMessage = '发送验证码';
              }else {
                // console.log("i:",i)
                this.sendBtnMessage = '正在发送('+ ( 60 - i ) +')';
              }
            },1000);
            let params = {
              phone: phone
            }
            this.$httpApi.get("/dev-api/getCodeByphone", params).then((res) => {
              console.log("res:", res)
              this.code = res.code;
              this.infoForm.uuid = res.uuid;
            }).catch((err) => {
              console.log("err:", err)
            });
          }else {
            console.log("手机号码输入错误")
          }
        });
      },
      /**
       * 修改密码
       */
      modifyPwd(){
        this.$refs.infoForm.validate((valid)=>{
          if(valid){
            console.log("格式正确")
            let phone = this.infoForm.phone;
            let aseKey = Generatekey();
            this.infoForm.aseKey = aseKey;
            this.infoForm.status = this.status

            let params = this.infoForm;
            console.log("修改密码params:", params);
            this.$httpApi.post("/dev-api/updatePwdByPhone",params).then((res)=>{
              console.log("res:",res)
              if(res.code == 200){
                this.active = 1;
              }else {
                this.$message.closeAll();
                this.$message.error("账号或手机号不正确");
              }
            }).catch((err)=>{
              console.log("修改失败:",err);
              this.$message.error("服务超时")
            })
          }else {
            console.log("格式有错误")
          }
        })
      },
      /**
       * 跳转到登录页面
       */
      gotoLogin(){
        this.$router.replace({
          path: "/login",
        })
      }
    }
  }
</script>

<style lang="scss">
  @import "@/styles/com/theme.scss";

  .view-container{

  .view2{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-container {
    margin-top: 50px;
    background-color:#fafafa;
    color: #45484b;
  }

  .fp-container {
    width: 100%;
    height: 762px;
    background: url("../../assets/images/login_banner.png") center / 100% 100% no-repeat;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

  .fp-content {
    height: 600px;

  }

  .form-content {
    margin: 0 auto;
    width: 800px;
    height: 650px;
    padding: 40px 50px;
    /*/ / border: 1 px solid #333;*/
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 3px 15px rgba($color: rgb(0, 0, 0), $alpha: 0.2);
    display: flex;
    flex-flow: column nowrap;

  /*justify-content: space-between;*/
  /*align-items: center;*/
  .steps-container {
    width: 100%;
    margin-bottom: 100px;
  }

  .info-form {
    width: 100%;
    margin: 0 auto;

  .el-form-item {
    margin-bottom: 30px;
  }

  .phone {

  .el-input__inner {
    width: 520px;
  }

  }
  .el-input__inner {
    width: 400px;
  }
  .commitButton{
    display: block;
    width: 200px;
    margin: 30px 100px;
  }
  }
  }

  }
  }




</style>
